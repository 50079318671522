'use strict';

var eventmap = [];
var eventname = '';
var ron = /^on/;
for (eventname in global) {
  if (ron.test(eventname)) {
    eventmap.push(eventname.slice(2));
  }
}
module.exports = eventmap;
var si = typeof setImmediate === 'function',
  tick;
if (si) {
  tick = function (fn) {
    setImmediate(fn);
  };
} else {
  tick = function (fn) {
    setTimeout(fn, 0);
  };
}
module.exports = tick;